if ($('.alertClosable').length) {
    if ($('.alertClosable').attr('data-close-text')) {
        var closeText = $('.alertClosable').attr('data-close-text'); // For different text or languages
    } else {
        var closeText = 'Close alert';
    }
    $('.alertClosable').append(
        $(document.createElement('button')).prop({
            type: 'button',
            innerHTML: '<span class="cross"></span><span class="visually-hidden">'+closeText+'</span>',
            class: 'button alert-close alertClose'
        })
    );

    $('.alertClose').on( "click", function() {
        $('.alertClosable').fadeOut();
    });
}
