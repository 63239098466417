// handlebars for timeline modals
var Handlebars = require("handlebars");

let template = {};
$(document).ready(function () {
    if ($('#handlebarsModal').length) {
        // grab the source
        const source = document.querySelector("#handlebarsModal").innerHTML;

        // compile it using Handlebars
        template = Handlebars.compile(source);

        $('.handlebarsModalTrigger').on('click', processModalContent);
    }
});

function processModalContent()
{
    context = JSON.parse(this.dataset.modalcontent);

    const html = template(context);

    // get the element to set the new HTML into
    const destination = document.querySelector(".handlebarsContent");

    // set the new HTML
    destination.innerHTML = html;
}
