$(function () {
    var body = $('body');
    var nav = $('.nav');
    var burger = $('.burger');
    var nav_trigger = $('.nav').find('ul li.menu-item-has-children > button, ul li.menu-item-has-children > a');
    var megamenu_trigger = $('.nav').find('ul li.menu-item-has-megamenu .menu-item-has-megamenu-button>button, ul li.menu-item-has-megamenu .menu-item-has-megamenu-button>a');
    var megamenu_child_trigger = $('.nav').find('ul li.menu-item-has-megamenu a.megamenu-link-has-children');
    var nav_align = 'right';
    var break_point = 992;
    var browser_width = window.innerWidth;
    var nav_width = 300;
    var nav_window = $('<div class="navwindow"></div>');
    body.append(nav_window);
    var nav_wrap = $('.navwrap');

    document.addEventListener('keydown', function (event) {
        const key = event.key;
        if (key === "Escape") {
            if (browser_width < break_point) {
                nav_reset();
                burger.focus();
            } else {
                $('.menu-item-has-children').removeClass('__active');
                $('.menu-item-has-megamenu').removeClass('__active');
                $('.menu-item-has-megamenu button').attr('aria-expanded', false);
                body.removeClass('__blur');
            }
        }
    });

    nav_window.click(function (event) {
        nav_reset();
    });

    //burger click
    burger.click(function (event) {
        event.preventDefault();
        $(this).toggleClass('__active');
        if (body.hasClass('__mobile')) {
            nav_reset();
        } else {
            nav_launch();
        }
    });

    /*nav reset*/
    var nav_reset = function nav_reset() {
        burger.removeClass('__active');
        nav_window.hide();
        body.removeClass('__mobile');
        nav_wrap.removeClass('__active');
        nav_wrap.find('ul, div').removeClass('__active');
        body.removeClass('__blur');
    };

    /*nav launch*/
    var nav_launch = function nav_launch() {
        nav_window.show();
        body.addClass('__mobile');
        nav_wrap.addClass('__active');
    };

    
    function initMenu() {
        var browser_width = window.innerWidth;

        $( ".navwrap ul").on( "mouseenter focusin", function() {
            $('.navwrap ul').each(function () {
                var elem = $(this)
                var rect = elem[0].getBoundingClientRect();
                if(rect.x + rect.width > window.innerWidth) {
                    $(this).addClass('left');
                }
            });
        });

         // nav dropdown on focus/keyboard navigation
        if (browser_width >= break_point) {
            nav_trigger.click(function (event) {
                if($(this).is('button')) {
                    event.preventDefault();
                    $(this).parent().siblings().removeClass('__active');
                    $(this).parent().siblings().find('.__active').removeClass('__active');
                    $(this).parent().siblings().find('[aria-expanded="true"]').attr('aria-expanded', false);
                    $(this).parent().toggleClass('__active');
                    if($(this).parent().hasClass( '__active' )) {
                        $(this).attr('aria-expanded', true);
                    } else {
                        $(this).attr('aria-expanded', false);
                        $(this).parent().find('.__active').removeClass('__active');
                    }
                }
            });
            megamenu_trigger.click(function (event) {
                if($(this).is('button')) {
                    event.preventDefault();
                    body.toggleClass('__blur');
                    $(this).parent().parent().siblings().removeClass('__active');
                    $(this).parent().parent().siblings().find('.__active').removeClass('__active');
                    $(this).parent().parent().siblings().find('[aria-expanded="true"]').attr('aria-expanded', false);
                    $(this).parent().parent().toggleClass('__active');
                    if($(this).parent().parent().hasClass( '__active' )) {
                        $(this).attr('aria-expanded', true);
                    } else {
                        $(this).attr('aria-expanded', false);
                        $(this).parent().parent().find('.__active').removeClass('__active');
                    }
                }
            });

            $(document).on("click", function (event) {
                if ($(event.target).closest(".nav[aria-label='Primary']").length === 0) {
                    nav.find('.__active').removeClass('__active');
                    nav.find('[aria-expanded="true"]').attr('aria-expanded', false);
                    body.removeClass('__blur');
                }
            });
        }

        if (browser_width < break_point) {
            nav_trigger.click(function (event) {
                event.stopPropagation();
                if ($(this).parent().children('ul').length > 0) {
                    $(this)
                        .parent().children('ul')
                        .addClass('__active');
                    $(this)
                        .parent().children('ul').find('li:first-child a, li:first-child button').focus();
                }
            });

            megamenu_trigger.click(function (event) {
                event.stopPropagation();

                $(this)
                    .parent().parent()
                    .toggleClass('__active');
                $(this)
                    .parent().parent()
                    .children('.menu-item-is-megamenu')
                    .toggleClass('__active');
                $(this)
                        .parent().siblings('.menu-item-is-megamenu').find('>div:first-child a, >div:first-child button').focus();
                    // $(this)
                //     .parent()
                //     .children('.menu-item-is-megamenu')
                //     .slideToggle();
            });

            megamenu_child_trigger.click(function (event) {
                $(this)
                    .toggleClass('__active');
                $(this)
                    .siblings('.megamenu-list')
                    .toggleClass('__active');
                // $(this)
                //     .siblings('.megamenu-list')
                //     .slideToggle();
            });

            // Disable root item link click
            $('.menu-item-has-children > a, .menu-item-has-megamenu .menu-item-has-megamenu-button> a, a.megamenu-link-has-children').click(function (event) {
                event.preventDefault();
            });
        }

        if (browser_width < break_point) {

            nav.find('a, button').attr('tabindex', '-1');
            var back_menu = $('.backto');
            back_menu.click(function (event) {
                event.preventDefault();
                event.stopPropagation();
                $(this)
                    .parent('ul, .menu-item-is-megamenu')
                    .removeClass('__active');
                $(this)
                    .parents('li')
                    .parent('ul')
                    .addClass('__active');
                $(this)
                    .parents('li').children('a').focus();
            });

        } else {
            nav_reset();
        }
        

        // Touch device check
        const isTouchDevice = () => {
            return window.matchMedia("(pointer: coarse)").matches
        }

        // Create view button for touchscreen devices
        var view_button = $('<li class="viewall"><a href="#">View</a></li>');
        
        // If desktop and touch device, disable link click and add extra view link
        if (browser_width > break_point && isTouchDevice()) {
            nav_trigger.click(function (event) {
                event.stopPropagation();
                if ($(this).children('ul').length > 0) {
                    event.preventDefault();
                }
            });

            nav_ul.prepend(view_button);
        }

        var view_all = $('.viewall');
        view_all.click(function (event) {
            event.stopPropagation();
        });

        var back_menu = $('.backto button');
        back_menu.click(function (event) {
            event.preventDefault();
            event.stopPropagation();
            // $(this).closest('.menu-item-has-megamenu').removeClass('__active');
            $(this).closest('.__active').removeClass('__active');
            if($(this).parent().parent().hasClass("menu-item-is-megamenu")) {
                body.removeClass('__blur');
            }
        });
    }
    
    initMenu();

    $(window).on('resize', function(){
        initMenu();
    });

});