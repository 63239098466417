$(function() {
    var break_point = 992;
    var target = $('#Video');
    var source = target.data('video');
    var html = '';

    function append_video() {
        html +=
            '<video autoplay playsinline loop muted oncanplay="this.muted=true" data-object-fit="cover">';
        html += '<source src="' + source + '" type="video/mp4">';
        html += '</video>';

        target.prepend(html);
    }

    function pause_play() {
        if (target.length) {
            var video = document.querySelector('video');
            var playPauseButton = document.querySelector('.playpause');
            var playPauseButtonText = document.querySelector(
                '#banner-video-controls-button-text'
            );

            if (playPauseButton) {
                playPauseButton.classList.add('__pause');

                // Play/Pause button
                playPauseButton.onclick = function() {
                    if (video.paused) {
                        // is paused, click to play
                        video.play();
                        playPauseButton.classList.remove('__play');
                        playPauseButton.classList.add('__pause');
                        playPauseButtonText.textContent = 'Pause';
                    } else {
                        // is playing, click to pause
                        video.pause();
                        playPauseButton.classList.add('__play');
                        playPauseButton.classList.remove('__pause');
                        playPauseButtonText.textContent = 'Play';
                    }
                };
            }
        }
    }

    function screenSizeChecker() {
        var browser_width = window.innerWidth;

        if (browser_width > break_point) {
            if (target.has('video').length == 0) {
                html = '';
                append_video();
                pause_play();
            }
        } else if (browser_width <= break_point) {
            target.find('video').remove();
        }
    }
    screenSizeChecker();
    
    $(window).on('resize', function(){
        screenSizeChecker();
    });
});
